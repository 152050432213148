<template>
  <b-card>
    <b-form @submit.prevent>
      <b-row>
        <b-col cols="12">
          <b-form-group
            label="Nome"
            label-for="name"
          >
            <b-form-input
              v-model="name"
              id="name"
              :class="{
                'is-invalid': v$.name.$error
              }"
            />
            <div class="invalid-feedback">
              <span
                v-if="v$.name.required.$invalid"
              >
                Você deve informar um nome
              </span>
            </div>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Descrição"
            label-for="description"
          >
            <b-form-textarea
              id="description"
              placeholder="Descreva a solução financeira"
              v-model="description"
            ></b-form-textarea>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group
            label="Empresa"
            label-for="company"
          >
            <v-select
              v-model="partner_company_id"
              :reduce="companiesList => companiesList.id"
              :options="companiesList"
              label="name"
              dir="ltr"
              :loading="loading.company"
              @input="partnerCompanyChanged"
              :class="getSelectErrorClass(v$.partner_company_id.$error)"
            >
              <template #no-options="{ search, searching, loading }">
                Sem resultados
              </template>
            </v-select>
            <div class="invalid-feedback">
              <span
                v-if="v$.partner_company_id.required.$invalid"
              >
                Você deve selecionar uma empresa
              </span>
            </div>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group
            label="Produto"
            label-for="product"
          >
            <v-select
              id="product"
              v-model="product_id"
              :reduce="productList => productList.id"
              :options="productsList"
              label="name"
              dir="ltr"
              :loading="loading.product"
              :disabled="!partner_company_id"
              :class="getSelectErrorClass(v$.product_id.$error)"
            >
              <template #no-options="{ search, searching, loading }">
                Sem resultados
              </template>
            </v-select>
            <div class="invalid-feedback">
              <span
                v-if="v$.product_id.required.$invalid"
              >
                Você deve selecionar um produto
              </span>
            </div>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-row class="align-items-center">
            <b-col cols="12" md="5">
              <b-form-group
                label="Nome do campo"
                label-for="name"
              >
                <b-form-input
                  id="name"
                  value="Prêmio Mensal"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col cols="8" md="4">
              <b-form-group
                label="Tipo de dado"
                label-for="data-type"
              >
                <b-form-input
                  id="data-type"
                  value="Financeiro"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col cols="4" md="3">
              <b-form-checkbox
                v-model="monthly_premium_field.required"
                switch
                label="Obrigatório"
                class="mt-1"
              >
                <span>Obrigatório</span>
              </b-form-checkbox>
            </b-col>
          </b-row>
          <template v-for="(field, index) in financial_solution_fields">
            <b-row class="align-items-center">
              <b-col cols="12" md="5">
                <b-form-group
                  label="Nome do campo"
                  :label-for="`fieldName-${index}`"
                >
                  <b-form-input
                    :id="`fieldName-${index}`"
                    v-model="field.field_name"
                    :class="{
                      'is-invalid' :
                      v$.financial_solution_fields.$dirty &&
                      v$.financial_solution_fields.$each.$response.$errors[index].field_name.length
                    }"
                  />
                  <div class="invalid-feedback">
                    <span
                      v-if="
                        v$.financial_solution_fields.$dirty &&
                        v$.financial_solution_fields.$each.$response.$errors[index].field_name.length
                      "
                    >
                      Você deve informar o nome do campo
                    </span>
                  </div>
                </b-form-group>
              </b-col>
              <b-col cols="6" md="4">
                <b-form-group
                  label="Tipo do dado"
                  :label-for="`fieldType-${index}`"
                >
                  <v-select
                    v-model="field.value_type"
                    :id="`fieldType-${index}`"
                    :reduce="fieldOption => fieldOption.id"
                    label="name"
                    dir="ltr"
                    :options="customFieldTypes"
                    :disabled="customFieldTypes.length === 0"
                    :class="getSelectErrorClass(
                      v$.financial_solution_fields.$dirty &&
                      v$.financial_solution_fields.$each.$response.$errors[index].value_type.length
                    )"
                  >
                    <template #no-options="{ search, searching, loading }">
                      Sem resultados
                    </template>
                  </v-select>
                  <div class="invalid-feedback">
                    <span
                    v-if="
                      v$.financial_solution_fields.$dirty &&
                      v$.financial_solution_fields.$each.$response.$errors[index].value_type.length
                    "
                    >
                      Você deve informar o tipo do campo
                    </span>
                  </div>
                </b-form-group>
              </b-col>
              <b-col cols="3" md="2">
                <b-form-checkbox
                  v-model="field.required"
                  :id="`fieldSwitch-${index}`"
                  switch
                  class="mt-1"
                >
                  <span>Obrigatório</span>
                </b-form-checkbox>
              </b-col>
              <b-col cols="3" md="1">
                <b-button
                  variant="outline-danger"
                  size="sm"
                  @click.prevent="removeField(index)"
                >
                  <feather-icon
                    icon="TrashIcon"
                  />
                </b-button>
              </b-col>
              <b-col 
                v-if="field.value_type === listValueType"
                cols="12"
              >
                <b-form-group
                  :label="`Opções para o campo ${field.field_name || 'de lista'} separadas por ponto e vírgula (;)`"
                  :label-for="`fieldOptions-${index}`"
                >
                  <b-form-input
                    :id="`fieldOptions-${index}`"
                    v-model="field.options"
                    :class="{
                      'is-invalid' :
                      v$.financial_solution_fields.$dirty &&
                      v$.financial_solution_fields.$each.$response.$errors[index].field_name.length
                    }"
                  />
                  <div class="invalid-feedback">
                    <span
                      v-if="
                        v$.financial_solution_fields.$dirty &&
                        v$.financial_solution_fields.$each.$response.$errors[index].options &&
                        v$.financial_solution_fields.$each.$response.$errors[index].options.length
                      "
                    >
                      Você deve informar as opções da lista
                    </span>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
          </template>
          <b-button
            class = "mb-1"
            variant="outline-primary"
            size="sm"
            @click.prevent="addField()"
          >
            <feather-icon
              icon="PlusCircleIcon"
              class="mr-50"
            />
            <span>
              Adicionar campo
            </span>
          </b-button>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col cols="12">
          <h4>LTV bruto</h4>
          <b-row class="align-items-center" v-for="(ltvField,index) in financial_solution_calculation_rules" :key="index">
            <b-col cols="12" md="6">
              <b-form-group
                label="Campo de refêrencia"
                :label-for="`fieldType-${index}`"
              >
                <v-select
                  v-model="ltvField.financial_solution_field_id"
                  :id="`fieldType-${index}`"
                  :reduce="fieldOption => fieldOption.id"
                  label="field_name"
                  dir="ltr"
                  :options="filterFields"
                  :class="getSelectErrorClass(
                      v$.financial_solution_calculation_rules.$dirty &&
                      v$.financial_solution_calculation_rules.$each.$response.$errors[index].financial_solution_field_id.length
                  )"
                >
                  <template #no-options="{ search, searching, loading }">
                    Sem resultados
                  </template>
                </v-select>
                <div class="invalid-feedback">
                  <span
                    v-if="
                      v$.financial_solution_calculation_rules.$dirty &&
                      v$.financial_solution_calculation_rules.$each.$response.$errors[index].financial_solution_field_id.length
                    "
                  >
                    Você deve informar o nome do campo
                  </span>
                </div>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="5">
            <multiplier-factor-input
              :id="`ltvFieldMultiplier-${index}`"
              label="Fator multiplicador"
              v-model="ltvField.multiplier"
              :thereIsError="
                v$.financial_solution_calculation_rules.$dirty &&
                v$.financial_solution_calculation_rules.$each.$response.$errors[index].multiplier.length > 0
              "
              errorMessage="Você deve informar um valor multiplicador válido"
            />
            </b-col>
            <b-col cols="12" md="1">
              <b-button
                variant="outline-danger"
                size="sm"
                @click.prevent="removeLtvCalculationRuleField(index)"
              >
                <feather-icon
                  icon="TrashIcon"
                />
              </b-button>
            </b-col>
          </b-row>
          <b-button
            class = "mb-1"
            variant="outline-primary"
            size="sm"
            @click.prevent="addLtvCalculationRuleField()"
          >
            <feather-icon
              icon="PlusCircleIcon"
              class="mr-50"
            />
            <span>
              Adicionar regra de cálculo
            </span>
          </b-button>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col cols="12">
          <h4>LTV líquido</h4>
          <b-form-checkbox
            v-model="net_value_apply_tax"
            switch
          >
            <span>Imposto será descontado do valor líquido</span>
          </b-form-checkbox>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col cols="12">
          <h4>Conciliação</h4>
          <b-form-checkbox
            v-model="automatic_conciliation"
            switch
          >
            <span>Conciliação automática</span>
          </b-form-checkbox>
        </b-col>
      </b-row>
      <template v-if="automatic_conciliation">
        <b-row class="mt-1">
          <b-col cols="12" md="4">
            <b-form-group
              label="Id Pagar-me"
              label-for="conciliator-id"
            >
              <b-form-input
                id="conciliator-id"
                v-model="conciliator_id"
                placeholder="Ex.: 123456"
                :class="{ 'is-invalid': v$.conciliator_id.$error}"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4">
            <money-input
              id="conciliator-amount"
              label="Valor"
              :value="conciliator_amount"
              :thereIsError="v$.conciliator_amount.$error"
              errorMessage="Você deve fornecer um montante válido"
            />
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col cols="12">
            <b-form-group
              label="Metadados"
              label-for="metadata"
            >
              <b-form-input
                id="metadata"
                v-model="metadata"
                placeholder="Ex.: {whatsapp_support: true}"
                :class="{ 'is-invalid': v$.metadata.$error}"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </template>
      <b-row class="mt-1">
        <b-col cols="12">
          <h4>Comissionamento</h4>
          <b-form-checkbox
            v-model="continuous_commission"
            switch
          >
            <span>Comissão contínua</span>
          </b-form-checkbox>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col cols="6">
          <b-form-group
            label="Número de parcelas para PNH, PNC e PNP"
            label-for="parcelas"
          >
            <b-form-input
              id="parcelas"
              v-model="commission_installments"
              placeholder="Ex.: 12"
              :class="{ 'is-invalid': v$.commission_installments.$error}"
            />
            <div class="invalid-feedback">
              <span v-if="
                v$.commission_installments.required.$invalid
                  || v$.commission_installments.numeric.$invalid
                  || v$.commission_installments.minValue.$invalid
              ">
                Informe um número válido
              </span>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col cols="9">
          <b-form-group
            label="Configuração do provisionamento"
            label-for="provisioning"
          >
            <v-select
              id="provisioning"
              v-model="provisioning_type"
              :reduce="option => option.id"
              :options="provisioningTypes"
              label="name"
              dir="ltr"
              :loading="loading.provisioning_types"
              :class="getSelectErrorClass(v$.provisioning_type.$error)"
            >
              <template #no-options="{ search, searching, loading }">
                Sem resultados
              </template>
            </v-select>
            <div class="invalid-feedback">
              <span
                v-if="v$.provisioning_type.required.$invalid"
              >
                Você deve selecionar um tipo de Configuração
              </span>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
      <template v-if="provisioning_type">
        <b-row class="mt-1">
          <b-col cols="6">
            <b-form-group
              label="Quantidade de dias para provisionar os pagamentos"
              label-for="provisioningDays"
            >
              <b-form-input
                id="provisioningDays"
                v-model="provisioning_days"
                type="number"
                :class="{ 'is-invalid': v$.provisioning_days.$error}"
              />
              <div class="invalid-feedback">
                <span v-if="
                  v$.provisioning_days.required.$invalid
                ">
                  Informe uma quantidade de dias para provisionar válida
                </span>
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <provisioning-config-input 
          ref="provisioningConfig" 
          :provisioningType="provisioning_type"
          :configs="provisioning_config"
          :provisioningSources="provisioningSources"
        />
      </template>
      <b-row class="justify-content-end mt-1">
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-secondary"
          :to="{ name: 'financial-solutions-list' }"
          :disabled="saving"
        >
          Cancelar
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="submit"
          variant="primary"
          class="mx-1"
          @click.prevent="save"
        >
          <b-spinner
            v-if="saving"
            small
          />
          <feather-icon
            v-else
            icon="SaveIcon"
            class="mr-50"
          />
          <span>
            {{ saving ? 'Salvando...' : 'Salvar' }}
          </span>
        </b-button>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import _ from 'lodash'
import {
  BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BCard, BSpinner, BFormTextarea
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import useVuelidate from '@vuelidate/core'
import { required, numeric, helpers, minValue } from '@vuelidate/validators'
import MultiplierFactorInput from '@/modules/shared/components/MultiplierFactorInput'
import { mapActions, mapGetters } from 'vuex'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { percentageToFloat, floatToMoney, moneyToFloat } from '@/helpers/converters'
import { getVueSelectErrorClass } from '@/helpers/validators'
import MoneyInput from '@/modules/shared/components/MoneyInput'
import ProvisioningConfigInput from '../components/ProvisioningConfigInput'
import * as types from '../store/types'
import * as sharedTypes from '@/modules/shared/store/types'
import { LIST_VALUE_TYPE, MONEY_VALUE_TYPE, MONTHLY_PREMIUM_FIELD_NAME, LTV_FIELD_NAME } from '@/constants/financial_solutions'
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BSpinner,
    vSelect,
    BFormTextarea,
    MultiplierFactorInput,
    MoneyInput,
    ProvisioningConfigInput
  },
  directives: {
    Ripple,
  },
  props: {
    isCopy: Boolean
  },
  setup() {
    return { toast: useToast() , v$: useVuelidate() }
  },
  data() {
    return {
      loading: {
        company: false,
        product: false,
        provisioning_types: false
      },
      saving: false,
      id: undefined,
      name: undefined,
      monthly_premium_field: {
        id: 0,
        field_name: MONTHLY_PREMIUM_FIELD_NAME,
        value_type: MONEY_VALUE_TYPE,
        required: true,
        options: undefined
      },
      product_id: undefined,
      partner_company_id: undefined,
      net_value_apply_tax: true,
      automatic_conciliation: false,
      conciliator_id: undefined,
      conciliator_amount: undefined,
      metadata: undefined,
      continuous_commission: false,
      commission_installments: 1,
      description: '',
      financial_solution_fields: [],
      financial_solution_calculation_rules: [],
      provisioning_type: undefined, 
      provisioning_config: undefined,
      provisioning_days: undefined
    }
  },
  validations() {
    return {
      name: { required },
      product_id: { required },
      partner_company_id: { required },
      financial_solution_fields: {
        $each: helpers.forEach({
          id: { required },
          field_name: { required },
          value_type: { required },
          required: { required },
          options: {}
        })
      },
      financial_solution_calculation_rules: {
        $each: helpers.forEach({
          financial_solution_field_id: { required },
          multiplier: { required }
        })
      },
      commission_installments: {
        required,
        numeric,
        minValue: (value) => value > 0
      },
      automatic_conciliation: { required },
      conciliator_id: {},
      conciliator_amount: {},
      metadata: {},
      provisioning_type: { required },
      provisioning_days: { required }
    }
  },
  computed: {
    ...mapGetters({
      customFieldTypes: sharedTypes.CUSTOM_FIELD_TYPES,
      companiesList: types.COMPANIES_FINANCIAL_SOLUTION,
      productsList: types.PRODUCTS_FINANCIAL_SOLUTION,
      provisioningTypes: types.PROVISIONING_TYPES
    }),
    filterFields: function() {
      const fields = [ ...this.financial_solution_fields, this.monthly_premium_field ]
      return _.filter(fields, (x) => x.value_type === MONEY_VALUE_TYPE)
    },
    financialSolutionFieldsWhithoutMonthlyPremium: function() {
      return _.filter(this.financial_solution_fields, (x) => x.id > 0)
    },
    listValueType: function() {
      return LIST_VALUE_TYPE
    },
    provisioningSources: function(){
      const fields = [ 
        {
          field_name: LTV_FIELD_NAME,
          value_type: MONEY_VALUE_TYPE,
        },
        this.monthly_premium_field,  
        ...this.financial_solution_fields
      ]
      return _.filter(fields, (x) => x.value_type === MONEY_VALUE_TYPE)
    }
  },
  mounted() {
    this.loading.company = true
    this.getCustomFieldTypes()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: 'Oops!',
            text: 'Ocorreu um erro ao carregar os tipos de campos do contrato. Entre em contato com o setor de TI.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    this.loading.provisioning_types = true
    this.getProvisioningTypes()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: 'Oops!',
            text: 'Ocorreu um erro ao carregar os tipos de provisionamento. Entre em contato com o setor de TI.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
      .finally(() => {
        this.loading.provisioning_types = false
      })
    this.getCompanies()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: 'Oops!',
            text: 'Ocorreu um erro ao carregar os modelos de distribuição das comissões. Entre em contato com o setor de TI.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
      .finally(() => {
        if (!this.$route.params.id) {
          this.loading.company = false
        }
      })
      if (this.$route.params.id) {
        this.getFinancialSoulutionById(this.$route.params.id)
          .then(response => {
            const {
              id,
              name,
              description,
              financial_solution_fields,
              financial_solution_calculation_rules,
              product_id,
              partner_company_id,
              net_value_apply_tax,
              continuous_commission,
              commission_installments,
              automatic_conciliation,
              conciliator_id,
              conciliator_amount,
              metadata,
              provisioning_type,
              provisioning_config,
              provisioning_days
            } = response.data
            if(!this.isCopy){
              this.id = id
              this.name = name
            }
            this.description = description
            
            const all_fields = [ ...financial_solution_fields ]
            const monthly_premium_fields = _.remove(all_fields, {
                field_name: MONTHLY_PREMIUM_FIELD_NAME
            })
            if (monthly_premium_fields.length > 0) {
              this.monthly_premium_field = monthly_premium_fields[0]
            }

            this.financial_solution_fields = _.map(
              all_fields,
              (field) => {
                if (field.value_type === LIST_VALUE_TYPE && field.options_list) {
                  field.options = field.options_list.join('; ')
                }
                return field 
              }
            )
            this.financial_solution_calculation_rules = _.map(
              financial_solution_calculation_rules,
              (rule) => { return { ...rule, multiplier: rule.multiplier.toFixed(4) } }
            )
            this.getProductsList(partner_company_id)
            this.product_id = product_id
            this.partner_company_id = partner_company_id
            this.net_value_apply_tax = net_value_apply_tax
            this.continuous_commission = continuous_commission
            this.commission_installments = commission_installments
            this.automatic_conciliation = automatic_conciliation
            this.conciliator_id = conciliator_id
            this.conciliator_amount = floatToMoney(conciliator_amount)
            this.metadata = metadata
            this.provisioning_type = provisioning_type
            this.provisioning_config = provisioning_config
            this.provisioning_days = provisioning_days
            this.v$.$touch()
          })
          .catch(() => {
            this.toast({
              component: ToastificationContent,
              props: {
                title: 'Oops!',
                text: 'Ocorreu um erro ao carregar a solução financeira selecionada. Entre em contato com o setor de TI.',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
          .finally(() => {
            this.loading.company = false
          })
      } else {
        this.addLtvCalculationRuleField()
      }
  },
  methods: {
    ...mapActions({
      getCustomFieldTypes: sharedTypes.GET_CUSTOM_FIELD_TYPES,
      saveFinancialSolution: types.SAVE_FINANCIAL_SOLUTION,
      getFinancialSoulutionById: types.GET_FINANCIAL_SOLUTION,
      getCompanies: types.GET_COMPANIES_FINANCIAL_SOLUTION,
      getProducts: types.GET_PRODUCTS_FINANCIAL_SOLUTION,
      getProvisioningTypes: types.GET_PROVISIONING_TYPES
    }),
    async save() {
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect){
        this.toast({
          component: ToastificationContent,
          props: {
            title: 'Oops!',
            text: 'Existem erros no formulário que precisam ser corrigidos.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        return
      }
      this.saving = true
      const {
        id,
        name,
        description,
        product_id,
        partner_company_id,
        commission_installments,
        net_value_apply_tax,
        continuous_commission,
        automatic_conciliation,
        conciliator_id,
        conciliator_amount,
        metadata,
        monthly_premium_field,
        financial_solution_fields,
        financial_solution_calculation_rules,
        provisioning_type,
        provisioning_days
      } = this

      let all_financial_solution_fields = [ ...financial_solution_fields, monthly_premium_field ]

      let fields = _.map(
        all_financial_solution_fields,
        (field) => {
          if (field.value_type === LIST_VALUE_TYPE) {
            let option_values = (field.options || '').split(';')
            option_values = _.map(option_values, (option) => option.trim())
            _.remove(option_values, item => !item)
            field.options_list = option_values
          }
          return field
        }
      )

      let payload = {
        id,
        name,
        description,
        product_id,
        partner_company_id,
        commission_installments,
        net_value_apply_tax,
        continuous_commission,
        automatic_conciliation,
        conciliator_id,
        conciliator_amount: moneyToFloat(conciliator_amount),
        metadata,
        provisioning_type,
        provisioning_days,
        provisioning_config: this.$refs.provisioningConfig.getJsonData(),
        financial_solution_fields: fields,
        financial_solution_calculation_rules: _.map(
          financial_solution_calculation_rules,
          (rule) => { return { ...rule, multiplier: percentageToFloat(rule.multiplier) } }
        )
      }
      this.saveFinancialSolution(payload)
        .then(response => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Sucesso',
              text: `O produto foi ${this.$route.params.id ? 'atualizado' : 'criado' } com sucesso`,
              icon: 'CoffeeIcon',
              variant: 'success',
            },
          })
          this.$router.push({ name: 'financial-solutions-list' })
        })
        .catch(error => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Oops!',
              text: error?.response?.data?.message ||
                'Ocorreu um erro ao carregar a solução financeira selecionada. Entre em contato com o setor de TI.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          this.saving = false
        })
    },
    getSelectErrorClass(thereIsError) {
      return getVueSelectErrorClass(thereIsError)
    },
    removeField(field_index) {
      this.financial_solution_fields.splice(field_index, 1)
      var searchValue = { 'financial_solution_field_id': field_index }
      var financial_solution_calculation_array_position = _.findIndex(this.financial_solution_calculation_rules, searchValue)
      this.financial_solution_calculation_rules.splice(financial_solution_calculation_array_position, 1)
    },
    addField() {
      this.financial_solution_fields.push({
        id: this.financial_solution_fields.length + 1,
        field_name: undefined,
        value_type: undefined,
        required: false,
        options: undefined
      })
    },
    removeLtvCalculationRuleField(field_index) {
      this.financial_solution_calculation_rules.splice(field_index, 1)
    },
    addLtvCalculationRuleField() {
      this.financial_solution_calculation_rules.push({
        financial_solution_field_id: undefined,
        name: undefined,
        multiplier: undefined,
      })
    },
    partnerCompanyChanged(partner_company_id) {
      this.product_id = undefined
      this.getProductsList(partner_company_id)
    },
    getProductsList(partner_company_id){
      if (partner_company_id) {
        this.getProducts({ partner_company_id })
          .catch(() => {
            this.toast({
              component: ToastificationContent,
              props: {
                title: 'Oops!',
                text: 'Ocorreu um erro ao carregar os produtos. Entre em contato com o setor de TI.',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }
    }
  }
}
</script>
