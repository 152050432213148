<template>
  <div v-if="!loading">
    <b-row class="mt-1">
      <b-col cols="6">
        <b-form-group
          label="Fonte do dado"
          label-for="provisioningAmountSource"
        >
          <v-select
            id="provisioningAmountSource"
            v-model="amount_source"
            :reduce="option => option.field_name"
            :options="provisioningSources"
            label="field_name"
            dir="ltr"
            :class="{ 'is-invalid':  v$.amount_source.$dirty && v$.amount_source.required.$invalid}"
          >
            <template #no-options="{ search, searching, loading }">
              Sem resultados
            </template>
          </v-select>
          <div class="invalid-feedback">
            <span
              v-if="v$.amount_source.$dirty && v$.amount_source.required.$invalid"
            >
              Você deve selecionar um campo fonte do dado.
            </span>
          </div>
        </b-form-group>
      </b-col>
    </b-row>
    <template v-if="provisioningType == types.SIMPLE_MONTH_PERCENTAGE">
      <b-row>
        <b-col
          cols="12"
          md="6"
          class="mt-1"
        >
          <b-form-group
            label="Quantidade total de meses"
            label-for="months"
          >
            <b-form-input
              id="months"
              v-model="months"
              :class="{ 'is-invalid': v$.months.$dirty && v$.months.required.$invalid}"
            />
            <div class="invalid-feedback">
              <span v-if="v$.months.$dirty && v$.months.required.$invalid">
                Você deve informar a quantidade de meses
              </span>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
    </template>
    <template v-if="provisioningType == types.MONTH_INTERVAL_PERCENTAGE">
      <template v-for="(field, index) in month_interval_percentages">
        <b-row class="mt-1" v-if="index > 0">
          <b-col cols="6">
            <b-form-group
              label="Fonte do dado"
              label-for="provisioningAmountSource"
            >
              <v-select
                id="provisioningAmountSource"
                v-model="field.amount_source"
                :reduce="option => option.field_name"
                :options="provisioningSources"
                label="field_name"
                dir="ltr"
                :class="{ 'is-invalid':  
                  v$.month_interval_percentages.$dirty &&
                  v$.month_interval_percentages.$each.$response.$errors[index].amount_source.length
                }"
              >
                <template #no-options="{ search, searching, loading }">
                  Sem resultados
                </template>
              </v-select>
              <div class="invalid-feedback">
                <span
                  v-if=" 
                  v$.month_interval_percentages.$dirty &
                  v$.month_interval_percentages.$each.$response.$errors[index].amount_source.length"
                >
                  Você deve selecionar um campo fonte do dado.
                </span>
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="3"
            class="mt-1"
          >
            <b-form-group
              label="Mês inicial"
              label-for="initialMonth"
            >
              <b-form-input
                id="initialMonth"
                v-model="field.initial_month"                
                type="number"
                :class="{ 'is-invalid':  
                  v$.month_interval_percentages.$dirty &&
                  v$.month_interval_percentages.$each.$response.$errors[index].initial_month.length
                }"
              />
              <div class="invalid-feedback">
                <span v-if="
                  v$.month_interval_percentages.$dirty &&
                  v$.month_interval_percentages.$each.$response.$errors[index].initial_month.length
                ">
                  Você deve informar o mês inicial
                </span>
              </div>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="3"
            class="mt-1"
          >
            <b-form-group
              label="Mês final"
              label-for="finalMonth"
            >
              <b-form-input
                id="finalMonth"
                v-model="field.final_month"
                type="number"
                :class="{ 'is-invalid':  
                  v$.month_interval_percentages.$dirty &&
                  v$.month_interval_percentages.$each.$response.$errors[index].final_month.length
                }"
              />
              <div class="invalid-feedback">
                <span v-if="
                  v$.month_interval_percentages.$dirty &&
                  v$.month_interval_percentages.$each.$response.$errors[index].final_month.length
                ">
                  Você deve informar o mês final
                </span>
              </div>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="3"
            class="mt-1"
          >
            <percentage-input
              id="intervalPercentage"
              label="Percentual"
              v-model="field.percentage"
              :thereIsError="v$.month_interval_percentages.$dirty &&
                v$.month_interval_percentages.$each.$response.$errors[index].percentage.length > 0"
              errorMessage="Você deve informar um percentual válido"
            />
          </b-col>
          <b-col cols="12" md="1">
            <b-button
              v-if="canRemoveField(month_interval_percentages, index)"
              variant="outline-danger"
              size="sm"
              class="mt-3"
              @click.prevent="removeField(month_interval_percentages, index)"
            >
              <feather-icon
                icon="TrashIcon"
              />
            </b-button>
          </b-col>
        </b-row>
      </template>
      <b-button
        class = "mb-1"
        variant="outline-primary"
        size="sm"
        @click.prevent="addIntervalPercentage"
      >
        <feather-icon
          icon="PlusCircleIcon"
          class="mr-50"
        />
        <span>
          Adicionar intervalo
        </span>
      </b-button>
    </template>
    <template v-if="provisioningType == types.PARCEL_PERCENTAGE">
      <template v-for="(field, index) in parcel_percentages">
        <b-row>
          <b-col
            cols="12"
            md="3"
            class="mt-1"
          >
            <b-form-group
              label="Mês"
              label-for="month"
            >
              <b-form-input
                id="month"
                v-model="field.month"
                disabled
                type="number"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="3"
            class="mt-1"
          >
            <percentage-input 
              id="parcelPercentage"
              label="Percentual"
              v-model="field.percentage"
              :thereIsError="v$.parcel_percentages.$dirty &&
                v$.parcel_percentages.$each.$response.$errors[index].percentage.length > 0"
              errorMessage="Você deve informar um percentual válido"
            />
          </b-col>
          <b-col cols="12" md="1">
            <b-button
              v-if="canRemoveField(parcel_percentages, index)"
              variant="outline-danger"
              size="sm"
              class="mt-3"
              @click.prevent="removeField(parcel_percentages, index)"
            >
              <feather-icon
                icon="TrashIcon"
              />
            </b-button>
          </b-col>
        </b-row>
      </template>
      <b-button
        class = "mb-1"
        variant="outline-primary"
        size="sm"
        @click.prevent="addParcelPercentage"
      >
        <feather-icon
          icon="PlusCircleIcon"
          class="mr-50"
        />
        <span>
          Adicionar mês
        </span>
      </b-button>
    </template>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BButton
} from 'bootstrap-vue'
import { mapActions } from 'vuex'
import { useToast } from 'vue-toastification/composition'
import { required, helpers } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import vSelect from 'vue-select'
import PercentageInput from '@/modules/shared/components/PercentageInput'
import * as financialSolutionTypes from '@/constants/financial_solutions'
import { percentageToFloat } from '@/helpers/converters'

function initialState(){
  return {
    loading: true,
    amount_source: undefined,
    months: undefined,
    month_interval_percentages: [{
      amount_source: undefined,
      initial_month: undefined,
      final_month: undefined,
      percentage: undefined
    }],
    parcel_percentages: [{
      month: 1,
      percentage: undefined
    }],
  }
}
export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    PercentageInput,
    vSelect
  },
  props:{
    provisioningType: String,
    provisioningSources: Array,
    configs: Object
  },
  watch: { 
    provisioningType: function(){
      Object.assign(this.$data, initialState())
      this.loading = false
    },
  },
  setup() {
    return { 
      toast: useToast(), 
      v$: useVuelidate()
    }
  },
  data() {
    return initialState()
  },
  validations() {
    return {
      amount_source: { required },
      months: this.provisioningType !== financialSolutionTypes.SIMPLE_MONTH_PERCENTAGE? {}:{ 
        required
      },
      month_interval_percentages: 
        this.provisioningType !== financialSolutionTypes.MONTH_INTERVAL_PERCENTAGE? {}:{
          $each: helpers.forEach({
            initial_month: { required },
            final_month: { required },
            percentage: {
              required,
              minValue: function(value){
                if(value){
                  let percentage = percentageToFloat(value.split(' ')[0])
                  if(percentage > 0){
                    return true
                  }
                  return false
                }
                return false
              }
            }
          })
      },
      parcel_percentages: 
        this.provisioningType !== financialSolutionTypes.PARCEL_PERCENTAGE? {}:{
          $each: helpers.forEach({
            month: { required },
            percentage:{ 
              required,
              minValue: function(value){
                if(value){
                  let percentage = percentageToFloat(value.split(' ')[0])
                  if(percentage > 0){
                    return true
                  }
                  return false
                }
                return false
              }
            }
          })
      },
    }
  },
  computed: {
    types: function(){
      return financialSolutionTypes
    }
  },
  mounted() {
    if(this.configs){
      const {
        amount_source,
        months,
        month_interval_percentages,
        parcel_percentages
      } = this.configs
      this.amount_source = amount_source
      this.months = months
      this.month_interval_percentages = month_interval_percentages
      this.parcel_percentages = parcel_percentages
    }
    this.loading = false
  },
  methods: {
    ...mapActions({
    }),
    getJsonData(){
      const{
        amount_source,
        months,
        month_interval_percentages,
        parcel_percentages
      } = this
      const data = {
        amount_source,
        months,
        month_interval_percentages,
        parcel_percentages
      }
      return JSON.stringify(data)
    },
    addIntervalPercentage(){
      this.month_interval_percentages.push({
        amount_source: undefined,
        initial_month: undefined,
        final_month: undefined,
        percentage: undefined,
      })
    },
    addParcelPercentage(){
      this.parcel_percentages.push({
        month: this.parcel_percentages.length + 1,
        percentage: undefined
      })
    },
    canRemoveField(arr, field_index){
      if(arr.length > 1 
        && field_index == arr.length - 1
      ){
        return true
      }
      return false
    },
    removeField(arr, field_index){
      if(this.canRemoveField(arr, field_index)){
        arr.splice(field_index, 1)
      }
    },
  }
}
</script>

<style lang="scss">
$themeColor: #f26237;
.form-group {
  margin-bottom: 0;
}
</style>
