export const LIST_VALUE_TYPE = 'list'

export const MONEY_VALUE_TYPE = 'money'

export const MONTHLY_PREMIUM_FIELD_NAME = 'Prêmio mensal'

export const LTV_FIELD_NAME = 'LTV bruto'

export const SIMPLE_MONTH_PERCENTAGE = 'simple_month_percentage'
export const MONTH_INTERVAL_PERCENTAGE = 'month_interval_percentage'
export const PARCEL_PERCENTAGE = 'parcel_percentage'
export const PAYMENT_PARCEL_PERCENTAGE = 'payment_parcel_percentage'
